import { graphql, useStaticQuery } from "gatsby"
import React from "react"
// import WelcomeModal from "../molecules/WelcomeModal"
import LocaleDialog from "../organisms/localeDialog/LocaleDialog"
import DatoLinkList from "./DatoLinkList"

// type DatoLocaleDialogProps = {}

const DatoLocaleDialog = (/*props: DatoLocaleDialogProps*/) => {
  // console.debug("Rendering DatoLocaleDialog")
  // Older implementation used a fragment on DatoCmsWelcomePage for mostly the same data
  // There's no "Welcome page" anymore so content was migrated to new "Location Dialog" single instance model
  const contentData = useStaticQuery<Queries.DatoLocationDialogQuery>(graphql`
    query DatoLocationDialog {
      datoCmsLocationDialog(originalId: { eq: "R0SeHzVzR82vwq4xMAGmuA" }, locale: "en") {
        title
        subtitle
        footerLinks {
          key: originalId
          ...DatoLinkList
        }
      }
    }
  `)
  if (!contentData.datoCmsLocationDialog) {
    return null
  }

  const { title, subtitle, footerLinks } = contentData.datoCmsLocationDialog

  return (
    <LocaleDialog
      title={title || "Choose location and language"}
      subtitle={subtitle || "Available products may depend on your location"}
      footer={footerLinks?.map(linkList => {
        if (!linkList) {
          return null
        }
        const { key, ...linkListProps } = linkList
        return <DatoLinkList key={key} {...linkListProps} />
      })}
    />
  )
}

export default DatoLocaleDialog
