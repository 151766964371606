import React, { createContext, useCallback, useContext, useEffect, useReducer } from "react"
import { DatoLocale, LocaleArea, isLocaleArea } from "../types"
import { getHttpArea } from "../utils"

/**
 * Name of the object stored in Locale Storage.
 *
 * NOTE! If using Cookiebot or similar: value needs to be categorized as "Necessary" "HTML cookie" or similar.
 */
const STORAGE_KEY = "user-preferences"
const DEFAUlT_AREA: LocaleArea = "global"

type UserPreferences = {
  /**
   * Users preferred location or `null` if user has not explicitly selected one.
   */
  localeArea: LocaleArea | null
  /**
   * Users preferred language or `null` if user has not explicitly selected one.
   * IMPORTANT! Notice that actual language of requested page is:
   * 1. Different concept and
   * 2. Should take and takes precedence almost always (TODO spec details)
   */
  language: DatoLocale | null
  /**
   * TODO Language detected from Browser.
   * This could be used as third level locale preference (page > user > browser > geo)
   */
  browserLanguage?: string
  initialized?: boolean
}

const initialState: UserPreferences = {
  localeArea: null,
  language: null,
  initialized: false,
}

/**
 * Context for User preferences, settings user has explicitly set themself
 */
const UserContext = createContext<UserPreferences>(initialState)

const UserDispatchContext = createContext<React.Dispatch<UserPrefAction>>(() => null)

export function useUserPreferences() {
  return useContext(UserContext)
  // const context = useContext(UserContext)
  // if (context.initialized === false) {
  //   throw new Error("useUserPreferences() must be used within a UserContext")
  // }
  // return context
}

export function useUserPreferencesDispatch() {
  const context = useContext(UserDispatchContext)
  if (context === null) {
    throw new Error("useUserPreferencesDispatch() must be used within a UserDispatchContext")
  }
  return context
}

export const UserContextProvider = (props: { children: React.ReactNode }) => {
  const [state, dispatch] = useReducer(userReducer, initialState)

  const needInit = !state.initialized

  // make a callback so that we can avoid `dispatch`, `getUserFromStorage`, `getHttpArea()` etc as useEffect dependencies
  const init = useCallback(() => {
    if (!needInit) {
      // User state is already set, no need to initialize it
      return
    }
    // console.debug("Getting userPreferences from localStorage")
    const userPrefs = getUserFromStorage() || initialState
    // `userPrefs` is something new from Locale Storage or undefined
    if (!isLocaleArea(userPrefs.localeArea)) {
      const area = getHttpArea()
      if (area) {
        userPrefs.localeArea = area
      }
    }

    // NOTE this passes also language from Local Storage. The actual page language might not be same as "user preference" language (by design)
    dispatch({ type: "INITIALIZE", userPreferences: userPrefs })
    return
  }, [needInit])

  // Initialize from Local Storage and browser when Provider is mounted
  useEffect(() => {
    init()
  }, [init])

  // Syncronize chages to Local Storage on every state change
  useEffect(() => {
    if (!state) {
      // Don't save. TODO if we ever have a user mechanism to clear preferences, handle it here
      return
    }
    saveUserPreferencesToStorage(state)
  }, [state])

  return (
    <UserContext.Provider value={state}>
      <UserDispatchContext.Provider value={dispatch}>{props.children}</UserDispatchContext.Provider>
    </UserContext.Provider>
  )
}

/** helpers */
/**
 *
 * @returns new state read from Locale Storage or reference to `initialState`
 */
function getUserFromStorage(): UserPreferences | undefined {
  const val = localStorage.getItem(STORAGE_KEY)
  return val ? JSON.parse(val) : undefined
}
function saveUserPreferencesToStorage(prefs: UserPreferences) {
  const { localeArea, language } = prefs
  // save only user selectable values, don't store `initialized` etc "internal" properties
  localStorage.setItem(STORAGE_KEY, JSON.stringify({ localeArea, language }))
}
/**
 * Compares two User Preferences and return true if they are the same from users POW.
 * @param a
 * @param b
 * @returns
 */
// function compareStates(a: UserPreferences, b: UserPreferences): boolean {
//   return a.language === b.language && a.localeArea === b.localeArea
// }

/** reducer */
type UserPrefAction =
  | {
      type: "INITIALIZE"
      userPreferences: UserPreferences
    }
  | {
      type: "SET_AREA"
      localeArea: LocaleArea
    }
  | {
      type: "SET_DEFAULT_AREA"
    }
  | {
      type: "SET_LANGUAGE"
      datoLanguage: DatoLocale
    }
  | {
      type: "SET"
      userPreferences: Omit<UserPreferences, "initialized">
    }
  | {
      type: "RESET"
    }

function userReducer(state: UserPreferences, action: UserPrefAction) {
  switch (action.type) {
    case "INITIALIZE": {
      // console.debug("Initializing user preferences with", action.userPreferences)
      return {
        ...state,
        ...action.userPreferences,
        initialized: true,
      }
    }
    case "SET_AREA": {
      return {
        ...state,
        localeArea: action.localeArea,
      }
    }
    case "SET_DEFAULT_AREA": {
      return {
        ...state,
        localeArea: DEFAUlT_AREA,
        initialized: true,
      }
    }
    case "SET_LANGUAGE": {
      return {
        ...state,
        language: action.datoLanguage,
      }
    }
    case "SET": {
      // console.debug("Setting user preferences to", action.userPreferences)
      return {
        ...action.userPreferences,
        initialized: state ? state.initialized : initialState.initialized,
      }
    }
    case "RESET": {
      return { ...state, ...initialState }
    }
    default:
      throw new Error()
  }
}
