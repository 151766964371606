import { LocaleDialogContextProvider } from "@nitro/gatsby-theme-harvia/src/context/LocaleDialogContext"
import PageContextProvider, { IPageContext } from "@nitro/gatsby-theme-harvia/src/context/PageContext"
import { UserContextProvider } from "@nitro/gatsby-theme-harvia/src/context/UserContext"
import { type GatsbyBrowser, type GatsbySSR } from "gatsby"
import React from "react"

type DataType = Record<string, unknown>

export const wrapRootElement: GatsbyBrowser<DataType, IPageContext>["wrapRootElement"] = ({ element }) => {
  return (
    <UserContextProvider>
      <LocaleDialogContextProvider value={false}>{element}</LocaleDialogContextProvider>
    </UserContextProvider>
  )
}

export const wrapPageElement: GatsbyBrowser<DataType, IPageContext>["wrapPageElement"] = ({ element, props }) => {
  return <PageContextProvider value={props.pageContext}>{element}</PageContextProvider>
}

export const onRenderBody: GatsbySSR["onRenderBody"] = args => {
  // Lisätään Zendesk chatti scripti saksankielisille sivuille.
  if (args.pathname.startsWith("/de/")) {
    args.setPostBodyComponents([
      <script
        key="ze-snippet"
        id="ze-snippet"
        src="https://static.zdassets.com/ekr/snippet.js?key=899994de-0563-42be-9cb0-e5a76ad373e3"
      />,
    ])
  }

  // add Leadoo to en-US pages
  if (args.pathname.toLowerCase().startsWith("/en-us/")) {
    args.setHeadComponents([
      <script
        key="leadoo-YIYATsHE"
        type="text/javascript"
        async
        src="https://bot.leadoo.com/bot/dynamic.js?company=YIYATsHE"
        data-cookieconsent="ignore"
      />,
    ])
  }
}
