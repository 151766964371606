import React, { useContext } from "react"
import type { DatoLocale } from "../types"
//import { useTranslation } from "react-i18next"

/**
 * gatsby-node'sta gatsby-template'lle annettu data.
 * Käytettävissä usePageContext() hookin avulla.
 */
export type IPageContext = {
  /** Current DatoCms locale */
  locale: DatoLocale
  /** Current PIM product language */
  localePim: string
  /** Dato fallback locales */
  fallbackLocales: string[]

  /** Current route / article / articleCategory id  on some pages */
  originalId?: string

  // ProductPage specifics
  sku?: string
  landing_pages_product_family?: string
  landing_pages_sku?: string

  // Product category page
  pimCode?: string
}

export const PageContext = React.createContext<IPageContext>({
  locale: "en",
  localePim: "en-US",
  fallbackLocales: [],
})

type PageContextProviderProps = {
  value: IPageContext
  children: React.ReactNode
}

export const PageContextProvider = (props: PageContextProviderProps) => {
  // console.debug("PageContentProvider init", props)
  const { value, children } = props

  return <PageContext.Provider value={value}>{children}</PageContext.Provider>
}

export default PageContextProvider

/**
 * Returns the locale the page was build with.
 */
export function usePageLocale() {
  const context = useContext(PageContext)

  return context.locale
}
