import React, { useEffect, useRef } from "react"
import * as s from "./ModalDialog.module.scss"

type ModalDialogProps = {
  children: React.ReactNode
  open: boolean
  onClose?: () => void
}

const ModalDialog = (props: ModalDialogProps) => {
  // console.debug("Rendering ModalDialog", props)
  // const [isOpen, setIsOpen] = useState(props.open)
  const modalRef = useRef<HTMLDialogElement | null>(null)

  const { open } = props

  const handleCloseModal: React.ReactEventHandler<HTMLDialogElement> = () => {
    // setIsOpen(false) // update internal state

    if (props.onClose) {
      // we have a callback registered so call it
      props.onClose()
    }
  }

  useEffect(() => {
    // console.debug(`ModalDialog effect on "open" while ref open status is "${modalRef.current?.open}"`)
    const modalElemement = modalRef.current
    if (!modalElemement) {
      return
    }
    if (open) {
      // console.debug(`ModalDialog opening on effect on "open" `)
      modalElemement.showModal()
    } else {
      // console.debug(`ModalDialog closing on effect on "open" `)
      modalElemement.close()
    }
  }, [open])

  return (
    <dialog
      aria-modal="true"
      className={s.dialog}
      ref={modalRef}
      onClose={handleCloseModal}
      onCancel={handleCloseModal}>
      {props.children}
    </dialog>
  )
}

export default ModalDialog
