import { graphql, useStaticQuery } from "gatsby"
import { useContext } from "react"
import { IPageContext, PageContext } from "../context/PageContext"

type Store = { [skuLocale: string]: string }

let _store: Store | null = null

type RoutePathMapData = {
  allSitePage: {
    nodes: Array<{
      path: string
      pageContext?: IPageContext
    }>
  }
}

/**
 * Palauttaa Dato CMS routeId:tä vastaavan, routesta tehdyn sivun URL polun
 */
const useProductPath = () => {
  const { locale: contextLocale } = useContext(PageContext)
  //const getRoutePath = useRoutePath()
  //const basePath = getRoutePath(useWebsite()?.productRootRoute?.originalId)

  const data = useStaticQuery<RoutePathMapData>(
    graphql`
      query DatoCmsProductPathMap {
        allSitePage(
          filter: { componentChunkName: { eq: "component---gatsby-theme-harvia-src-gatsby-templates-pim-product-tsx" } }
        ) {
          nodes {
            path
            pageContext
          }
        }
      }
    `
  )
  // console.debug("useProductPath()", data)

  if (!data) {
    return () => ""
  }

  if (_store === null) {
    const pathMap: Store = Object.create(null)
    data.allSitePage.nodes.forEach(n => {
      const context = n.pageContext
      if (context?.sku) {
        pathMap[`${context.sku}--${context.locale}`] = n.path
      }
    })
    _store = pathMap
  }

  function getProductPath(sku?: string | null, locale?: string): string {
    if (!sku) {
      return ""
    }
    const toLocale = locale || contextLocale
    const productPath = _store ? _store[`${sku}--${toLocale}`] : ""
    //const url = productPath || (basePath ? `${basePath}?id=${sku}` : "") // ohjataan väliaikaiseen online tuotesivuun
    return productPath // || ""
  }

  return getProductPath
}

export default useProductPath
