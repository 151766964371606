import { graphql, useStaticQuery } from "gatsby"
import { useContext } from "react"
import { IPageContext, PageContext } from "../context/PageContext"

let _store: Record<string, string> | null = null

type RoutePathMapData = {
  allSitePage: {
    nodes: {
      path: string
      pageContext: IPageContext
    }[]
  }
}

/**
 * Palauttaa Dato CMS routeId:tä vastaavan, routesta tehdyn sivun URL polun
 */
const useRoutePath = () => {
  const { locale: contextLocale } = useContext(PageContext)

  const data = useStaticQuery<RoutePathMapData>(
    graphql`
      query DatoCmsRoutePathMap {
        allSitePage(
          filter: { componentChunkName: { eq: "component---gatsby-theme-harvia-src-gatsby-templates-dato-route-tsx" } }
        ) {
          nodes {
            path
            pageContext
          }
        }
      }
    `
  )

  if (!data) {
    return () => undefined
  }

  // console.debug("useRoutePath()", data)

  if (_store === null) {
    // generate a map of "route Id + locale" as key to "URL path" as value for fast lookup
    const pathMap: Record<string, string> = Object.create(null)
    data.allSitePage.nodes.forEach(node => {
      const context = node.pageContext
      if (context.originalId) {
        const key = `${context.originalId}-${context.locale}`
        pathMap[key] = node.path
      }
    })
    _store = pathMap
  }

  //console.debug("useRoutePath()", data, _store)
  //console.debug("useRoutePath(), found", _store[routeId + toLocale])

  function getRoutePath(routeId?: string | null, locale?: string): string | undefined {
    // use `contextLocale` as fallback for `locale` and combine it with `routeId` to generate a key
    const key = `${routeId}-${locale || contextLocale}`
    return _store ? _store[key] : undefined
  }

  return getRoutePath
}

export default useRoutePath
