import { DatoLocale } from "gatsby-theme-harvia/src/types"
import { AreaKey } from "./localeOptions"

/**
 * Map of locale codes used in Dato to language name in that language.
 * Intenteded use in language menus etc,
 */
const langTitles = new Map<DatoLocale | string, string>([
  ["en", "English"],
  ["en-US", "English"],
  ["fi", "Suomi"],
  ["sv", "Svenska"],
  ["de", "Deutsch"],
  ["et", "Eesti"],
  ["jp", "Japan"],
])

export function getLangTitle(locale: DatoLocale | string): string | undefined {
  return langTitles.get(locale)
}

const localeAreaTitles = new Map<AreaKey, string>([
  ["africa", "Africa"],
  ["asia", "Asia"],
  // ["canada", "Canada"],
  ["europe", "Europe"],
  ["global", "Global"],
  ["middle-east", "Middle East"],
  ["north-europe", "Nordics & Baltics"],
  ["oceania", "Oceania"],
  ["north-america", "USA & Canada"],
  ["south-america", "South America"],
  // ["usa", "United States"],
])

export function getLocaleAreaTitle(localeArea: AreaKey): string | undefined {
  return localeAreaTitles.get(localeArea)
}
