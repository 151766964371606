import type { DatoLocale, LocaleArea } from "gatsby-theme-harvia/src/types"
import React from "react"
import { IoOpenOutline } from "react-icons/io5"
import * as s from "./LocaleDialogAreaMenu.module.scss"
import { getLangTitle, getLocaleAreaTitle } from "./localeLabels"
import type { AreaConfig } from "./localeOptions"

type LocaleDialogAreaMenuProps = {
  area: LocaleArea
  configs: AreaConfig[]
  onLocaleSelect: (locale: DatoLocale, area: LocaleArea) => void
}

const LocaleDialogAreaMenu = (props: LocaleDialogAreaMenuProps) => {
  const title = getLocaleAreaTitle(props.area) || props.area // TODO

  return (
    <div className={s.container}>
      <h3>{title}</h3>
      {props.configs.map(config => {
        return (
          <React.Fragment key={config.id}>
            {/* {config.subArea && <h4>{getLocaleAreaTitle(config.subArea) || config.subArea}</h4>} */}
            <ul className={s.locales}>
              {config.external && (
                <li key={config.external.locale}>
                  <a lang={config.external.locale} href={config.external.href}>
                    {getLangTitle(config.external.locale)} ({config.external.locale.toLocaleUpperCase()}){" "}
                    <IoOpenOutline />
                  </a>
                </li>
              )}
              {config.locales.map(locale => (
                <li key={locale}>
                  <button
                    lang={locale}
                    data-area={/*config.subArea ||*/ props.area}
                    onClick={() => props.onLocaleSelect(locale, /*config.subArea ||*/ props.area)}>
                    {getLangTitle(locale)} ({locale.toLocaleUpperCase()})
                  </button>
                </li>
              ))}
            </ul>
          </React.Fragment>
        )
      })}
    </div>
  )
}

export default LocaleDialogAreaMenu
