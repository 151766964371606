import { graphql, useStaticQuery } from "gatsby"
import type { DatoLocale } from "../types"

export type Website = {
  title: string
  locale: DatoLocale
  originalId: string
  footer: {
    originalId: string
  }
  mainMenu: {
    originalId: string
  }
  productRootRoute: {
    originalId: string
  }
  rootRoute: {
    originalId: string
  }
  articleRootRoute: {
    originalId: string
  }
  findDealerRoute: {
    originalId: string
  }
  saunaCalculatorRoute: {
    originalId: string
  }
  saunaPlannerRoute: {
    originalId: string
  }
}

/**
 * @deprecated
 */
const useWebsite = () => {
  const data = useStaticQuery(graphql`
    query UseWebsite {
      datoCmsWebsite {
        title
        #locale
        originalId
        footer {
          originalId
        }
        mainMenu {
          originalId
        }
        productRootRoute {
          originalId
        }
        rootRoute {
          originalId
        }
        articleRootRoute {
          originalId
        }
        findDealerRoute {
          originalId
        }
        saunaCalculatorRoute {
          originalId
        }
        saunaPlannerRoute {
          originalId
        }
      }
    }
  `)

  return data?.datoCmsWebsite
}

export default useWebsite
