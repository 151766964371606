module.exports = [{
      plugin: require('../../node_modules/gatsby-plugin-gatsby-cloud/gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby-plugin-loadable-components-ssr/gatsby-browser.js'),
      options: {"plugins":[],"useHydrate":false,"preloadTags":true},
    },{
      plugin: require('../../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-W34V658","includeInDevelopment":false,"defaultDataLayer":{"type":"object","value":{"platform":"gatsby"}},"routeChangeEventName":"gatsby-route-change","enableWebVitalsTracking":false,"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"296573139186045"},
    },{
      plugin: require('../../node_modules/gatsby-plugin-canonical-urls/gatsby-browser.js'),
      options: {"plugins":[],"siteUrl":"https://www.harvia.com"},
    },{
      plugin: require('../../gatsby-theme-harvia/gatsby-browser.tsx'),
      options: {"plugins":[],"siteUrl":"https://www.harvia.com","datoOptions":{"apiToken":"3c59585307370eeee6d097a369a203","previewMode":false,"disableLiveReload":false,"localeFallbacks":{"en-US":["en"],"fi":["en"],"sv":["en"],"et":["en","fi"],"de":["en"]},"localesToGenerate":["en","en-US","de","fi","sv","et"]},"productOptions":{"languages":["en-US","de-DE","fi-FI","sv-SE","et-EE"],"queryParams":["product_life_cycle_status:s3_maintenance","product_life_cycle_status:s4_rampdown","product_life_cycle_status:s5_discontinued"]},"cookiebotOptions":{"cookiebotId":"b061b95f-3ac0-4e95-98d0-9270094eb27a","manualMode":true,"includeInDevelopment":true},"facebookOptions":{"pixelId":"296573139186045","domainVerification":"fn3c4esq1bmbk8fy1is2oy3qmzu64u"}},
    },{
      plugin: require('../../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"Harvia global","short_name":"Harvia","icon":"src/images/logo.svg","lang":"en","background_color":"#EAE8E0","theme_color":"#D5D1C1","legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"505286353b973fb7fc9f950dd94e28cd"},
    },{
      plugin: require('../gatsby-browser.tsx'),
      options: {"plugins":[]},
    },{
      plugin: require('../../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
