import { Link } from "gatsby"
import React, { ReactNode } from "react"
import useArticlePath from "../../hooks/useArticlePath"
import useProductPath from "../../hooks/useProductPath"
import useRoutePath from "../../hooks/useRoutePath"
import useWebsite from "../../hooks/useWebsite"
import ExternalLink from "../atoms/ExternalLink"
// import styles from "./DatoLinkToRecord.module.scss"

type DatoLinkToRecordData =
  | ({ __typename: "DatoCmsRouteLink" } & Pick<Queries.DatoRouteLinkFragment, "route" | "urlParams">)
  | ({ __typename: "DatoCmsProductLink" } & Queries.DatoProductLinkFragment)
  | ({ __typename: "DatoCmsProductFinderLink" } & Pick<Queries.DatoProductFinderLinkFragment, "searchQuery">)
  | ({ __typename: "DatoCmsArticleLink" } & Queries.DatoArticleLinkFragment)
  | ({ __typename: "DatoCmsExternalUrl" } & Partial<Pick<Queries.DatoExternalUrlFragment, "url" | "title">>)

export type DatoLinkToRecordProps = { children?: ReactNode; newWindow?: boolean } & DatoLinkToRecordData

/**
 * Mappaa structured text linkit React komponentteihin
 */
const DatoLinkToRecord = (props: DatoLinkToRecordProps) => {
  // console.debug("Rendering DatoLinkToRecord", props)
  const website = useWebsite()
  const getProductPath = useProductPath()
  const getArticlePath = useArticlePath()
  const getRoutePath = useRoutePath()

  switch (props.__typename) {
    case "DatoCmsRouteLink": {
      const routePath = getRoutePath(props.route?.originalId)
      const url = `${routePath || ""}${props.urlParams || ""}`
      if (props.newWindow) {
        return (
          <a href={url} target={"_blank"}>
            {props.children}
          </a>
        )
      }
      return <Link to={url}>{props.children}</Link>
    }
    case "DatoCmsProductLink": {
      const target = getProductPath(props.sku)
      if (props.newWindow) {
        return (
          <a href={target} target={"_blank"}>
            {props.children}
          </a>
        )
      }
      return <Link to={`${target}`}>{props.children}</Link>
    }
    case "DatoCmsProductFinderLink": {
      const path = getRoutePath(website.productRootRoute?.originalId)
      const url = `${path}${props.searchQuery || ""}`
      if (props.newWindow) {
        return (
          <a href={url} target={"_blank"}>
            {props.children}
          </a>
        )
      }
      return <Link to={url}>{props.children}</Link>
    }
    case "DatoCmsArticleLink": {
      const target = getArticlePath(props.article?.originalId)
      const url = `${target}${props.urlParams || ""}`
      if (props.newWindow) {
        return (
          <a href={url} target={"_blank"}>
            {props.children}
          </a>
        )
      }
      return <Link to={url}>{props.children}</Link>
    }
    case "DatoCmsExternalUrl": {
      if (!props?.url) {
        console.warn("DatoLinkToRecord: Empty URL for ExternalLink")
        return null
      }
      return <ExternalLink href={props.url}>{props.children || props.title}</ExternalLink>
    }
    /* case "DatoCmsContactLocation":
        return (
          <a
            href={`https://www.google.com/maps/search/${[data.streetAddress, data.addressLocality, data.addressCountry]
              .filter(i => i)
              .join()}`}
            target="_blank">
            {children}
          </a>
        ) */

    default:
      console.warn(`Unknown type "${(props as any).__typename}". Rendering skipped`)
      return null
  }
}

export default DatoLinkToRecord
