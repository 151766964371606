import { navigate } from "gatsby"
import React, { useCallback, useEffect } from "react"
import { useLocaleDialog } from "../../../context/LocaleDialogContext"
import { useUserPreferences, useUserPreferencesDispatch } from "../../../context/UserContext"
import { isLocaleArea, type DatoLocale, type LocaleArea } from "../../../types"
import Button from "../../atoms/Button"
import ModalDialog from "../../molecules/ModalDialog"
import * as styles from "./LocaleDialog.module.scss"
import LocaleDialogAreaMenu from "./LocaleDialogAreaMenu"
import localeOptions from "./localeOptions"

type LocaleDialogProps = {
  title: string
  subtitle?: string
  footer?: React.ReactNode
}

/**
 * A modal dialog for locale and area/country selection
 */
const LocaleDialog = (props: LocaleDialogProps) => {
  // console.debug("Rendering LocaleDialog", props)
  const [isLocaleDialogOpen, setLocaleDialogOpen] = useLocaleDialog()
  const userPrefs = useUserPreferences()
  const userPrefDispatch = useUserPreferencesDispatch()

  const shouldOpenOnInit = userPrefs === null || !isLocaleArea(userPrefs.localeArea)
  // console.debug(
  //   `Rendering LocaleDialog. It's open? "${isLocaleDialogOpen}". It should be open? "${shouldOpenOnInit}", because userPrefs`,
  //   userPrefs
  // )

  const init = useCallback(() => {
    setLocaleDialogOpen(shouldOpenOnInit)
  }, [setLocaleDialogOpen, shouldOpenOnInit])

  useEffect(() => {
    init()
  }, [init])

  useEffect(() => {
    if (userPrefs.localeArea) {
      return
    }
    userPrefDispatch({ type: "SET_DEFAULT_AREA" })
  }, [userPrefDispatch, userPrefs.localeArea])

  const onCloseIntent = () => {
    // console.debug(`LocaleDialog onCloseIntent() called`, userPrefs)
    setLocaleDialogOpen(false)
  }

  function onLocaleSelect(locale: DatoLocale, area: LocaleArea) {
    // console.debug(`User selected area "${area}", locale "${locale}" with current locale "${userPrefs?.language}"`)
    userPrefDispatch({
      type: "SET",
      userPreferences: {
        localeArea: area,
        language: locale,
      },
    })

    // selecting a locale/area implies also intent to close the dialog
    onCloseIntent()

    // on language change or location set we should navigate to new language
    if (userPrefs?.language !== locale || !isLocaleArea(userPrefs?.localeArea)) {
      // console.debug(`Navigate from "${userPrefs.language}" to "${locale}"`)
      // TODO navigate to same page in different locale or if only area changes, refresh only wehen needed (product search pages etc)
      navigate(`/${locale}`) // `locale` is the new user selected "languange" here
      return
    }
    // on language change to different language than in the actual URL, we should also navigate to user selected new lang
    const urlPath = typeof window !== "undefined" ? window.location.pathname : ""
    const urlLang = urlPath.split("/")[1]
    // console.debug(
    //   `Should navigate? Lang in URL "${urlLang}", users new preference "${locale}" and previous preference "${userPrefs?.language}"`
    // )
    if (urlLang && urlLang !== locale) {
      // we should navigate even when `userPrefs.language === locale` because actual language changes
      // (user might have changed it directly by navigating to different /lang path without using LocaleDialog)
      navigate(`/${locale}`) // TODO navigate to same page in different locale
    }
  }

  return (
    <ModalDialog open={isLocaleDialogOpen} onClose={onCloseIntent}>
      <div className={styles.container}>
        <header>
          <h2 id="locale-dialog-title">{props.title}</h2>
          <p>{props.subtitle}</p>
          <Button label="X" aria-label="Close" onClick={onCloseIntent} priority="secondary" size="small" />
        </header>
        <main>
          <nav aria-labelledby="locale-dialog-title">
            {Array.from(localeOptions).map(([area, configs]) => {
              return <LocaleDialogAreaMenu key={area} area={area} configs={configs} onLocaleSelect={onLocaleSelect} />
            })}
          </nav>
        </main>
        <hr />
        <footer>{props.footer}</footer>
      </div>
    </ModalDialog>
  )
}

export default LocaleDialog
