import { graphql } from "gatsby"
import React from "react"
import DatoLinkToRecord from "./DatoLinkToRecord"

export type DatoLinkListProps = {
  titleElement?: "h1" | "h2" | "h3" | "h4" | "h5" | "h6"
  className?: string
} & Queries.DatoLinkListFragment

const DatoLinkList = (props: DatoLinkListProps) => {
  // console.debug("Rendering LinkList", props)
  const { titleElement: Title = "h2" } = props
  return (
    <div className={props.className}>
      {props.title && <Title>{props.title}</Title>}
      <ul>
        {props.links?.map(itm => {
          if (!itm?.title) {
            return null
          }
          return (
            <li key={itm.id}>
              <DatoLinkToRecord {...itm}>{itm.title}</DatoLinkToRecord>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default DatoLinkList

export const query = graphql`
  fragment DatoLinkList on DatoCmsLinkList {
    title
    links {
      ... on Node {
        __typename
      }
      ... on DatoCmsExternalUrl {
        id
        url
        title
      }
      ... on DatoCmsRouteLink {
        id
        urlParams
        title
        route {
          originalId
        }
      }
    }
  }
`
