// import timeZoneCityToCountry from "../data/timezone-cities-to-countries.json"
import { LocaleArea, PimUnitSystem, pimUnitSystems } from "./types"

/**
 * Find element from node with tree-like sub nodes
 */

export const findRecursive = <T = Record<string, unknown>>(
  node: T | T[],
  compareFn: (arg: T) => boolean,
  subArrayKey?: string // keyof T
) => {
  if (!node) return

  if (Array.isArray(node)) {
    for (const element of node) {
      const v = findRecursive(element, compareFn, subArrayKey)
      if (v) return v
    }

    return
  }

  const val = compareFn(node)
  if (val) {
    return node
  }

  const subProp = subArrayKey ? node[subArrayKey] : undefined
  if (!subProp) return

  return findRecursive(subProp as T, compareFn, subArrayKey)
}
/**
 * Vertaa kahta arrayta, ja palauttaa ensimmäisen elementin fromArraystä joka löytyy myös findArraystä tai undefined
 * @param fromArray
 * @param findArray
 * @returns
 */
export function findAny(fromArray: any[], findArray: any[]): any {
  return fromArray.find(itm => findArray.includes(itm))
}
/**
 * Make valid slug from any string
 * @param title Text
 * @param id Unique identifier, e.g. originalId to stringify and append to end of slug
 * @returns
 */
export const slugify = (title?: string | null, id?: string | number | null) => {
  const separator = "-"
  const title_str = title
    ?.toString()
    .normalize("NFD") // split an accented letter in the base letter and the acent
    .replace(/[\u0300-\u036f]/g, "") // remove all previously split accents
    .toLowerCase()
    .trim()
    .replace(/[^a-z0-9 ]/g, "") // remove all chars not letters, numbers and spaces (to be replaced)
    .replace(/\s+/g, separator)

  const id_str = id && parseInt(id.toString())?.toString(32)

  const slug = [title_str, id_str].filter(itm => itm).join(separator)

  return slug || undefined
}
/**
 * CamelCase, mutta isolla alkukirjaimella.
 * @param text
 * @returns
 */
export const camelCase = (text?: string) =>
  text
    ?.split(/\s|-/)
    .map((word: string) => word.charAt(0).toUpperCase() + word.substr(1))
    .join("")

/**
 * Send Google gtag or ga event & push event to dataLayer
 * @param {*} category string - required - The object that was interacted with (e.g.video)
 * @param {*} action string - required - Type of interaction (e.g. 'play')
 * @param {*} label string - optional - Useful for categorizing events (e.g. 'Spring Campaign')
 * @param {*} value number - optional - Numeric value associated with the event. (e.g. A product ID)
 */
export const trackEvent = (category: string, action: string, label?: string, value?: number) => {
  if (isBrowser && category && action) {
    //console.log("ga", category, action, label, value)

    const ga = window["ga"]
    const gtag = window["gtag"]
    const dataLayer = window["dataLayer"]

    // Google analytics events

    if (typeof gtag === "function") {
      gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
      })
    }
    if (typeof ga === "function") {
      ga("send", {
        hitType: "event",
        eventCategory: category,
        eventAction: action,
        eventLabel: label,
        value: value,
      })
    }

    // GTM DataLayer event

    if (Array.isArray(dataLayer)) {
      dataLayer.push({
        event: category + "." + action,
        action,
        category,
        label,
        value,
      })
    }
  }
}

/** Detect ssr mode */
export const isBrowser = typeof window !== "undefined"

/**
 * Find value for given locale from `_all*Locales` query result.
 *
 * @param locale Locale whose value to find
 * @param locales All locale/value pairs
 * @returns Value matching given locale or undefined
 */
export const localeValue = <T>(
  locale: string | null,
  locales?: readonly ({ readonly locale: string | null; value: T } | null)[] | null
) => {
  const res = locales?.find(loc => loc?.locale === locale)?.value
  return res !== null ? res : undefined
}

/**
 * Sort value for given locale from `_all*Locales` query result.
 *
 * @param locales All locale/value pairs
 * @returns Value matching given locale or undefined
 */
export const localeSort = <T>(
  locales?: readonly ({ readonly locale: string | null; readonly value: T } | null)[] | null
) => {
  if (!locales) return []
  return [...locales]?.sort((a, b) => {
    if (!a?.locale || !b?.locale) return 0
    return a.locale?.localeCompare(b.locale)
  })
}

/**
 * Find if given locale exists in `_all*Locales` query result.
 *
 * @param locale Locale whose value to find, the needle
 * @param locales Locales from _all*Locales query, the haystack
 * @returns
 */
export const localeExists = (
  locale: string | null,
  locales?: readonly ({ readonly locale: string | null } | null)[] | null
) => {
  return locales?.find(loc => loc?.locale === locale) !== undefined
}

/**
 * Type guard for string value
 */
export function isString(val: unknown): val is string {
  return typeof val === "string"
}
export function isObject(val: unknown): val is object {
  return val !== null && typeof val === "object"
}
export function isPimUnitSystem(val: unknown): val is PimUnitSystem {
  return isString(val) && pimUnitSystems.includes(val as PimUnitSystem)
}

// export function getUserLocationIntl() {
//   let region: string | undefined
//   let city: string | undefined
//   let country: string | undefined
//   let timeZone: string | undefined

//   if (Intl) {
//     timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone
//     const tzArr = timeZone.split("/")
//     region = tzArr[0]
//     city = tzArr[tzArr.length - 1]
//     country = timeZoneCityToCountry[city]
//   }

//   return { timeZone, region, city, country }
// }

const northEuropeCities = ["Helsinki", "Stockholm", "Tallinn"]

/**
 * Get browsers timezone and locale based guesses to approximate or guess location from.
 * @deprecated or TODO
 *
 * @example getTimeZoneLocation(Intl.DateTimeFormat().resolvedOptions())
 *
 * @param timeZone - timezone from `Intl.DateTimeFormat().resolvedOptions().timeZone`
 * @param locale - Locale from `Intl.DateTimeFormat().resolvedOptions().locale`
 * @returns
 */
export function getTimeZoneLocation(timeZone: string /*, locale?: string*/): LocaleArea | undefined {
  const [zone, city] = timeZone.split("/")

  switch (zone) {
    case "UTC":
      return
    case "Europe":
      if (northEuropeCities.includes(city)) {
        return "north-europe"
      }
      return "europe"
    case "America":
      // TODO "south-america", "usa", "canada"
      return "north-america"
    case "Africa":
      return "africa"
    case "Pacific":
    case "Asia":
      return "oceania"
    default:
      return "global"
  }
}

/**
 * Read cookie value from browser.
 *
 * TODO port to {@link https://developer.mozilla.org/en-US/docs/Web/API/Cookie_Store_API | CookieStore API} when it's globally available/supported
 * @param name - Name of the cookie
 * @returns The cookie value as `string` or `undefined` if Cookies cannot be read or no cookie with given name exists.
 */
function getCookie(name: string) {
  if (!document?.cookie) {
    return
  }
  const key = `${name}=`
  return decodeURIComponent(document.cookie)
    .split("; ")
    .find(row => row.startsWith(key))
    ?.split("=")[1]
}

/**
 * Azure CDN provided information about requester's continent through its abbreviation.
 *
 * Valid values are:
 * - AF: Africa
 * - AS: Asia
 * - EU: Europe
 * - NA: North America
 * - OC: Oceania
 * - SA: South America
 */
type AzureGeoContinent =
  | "AF" // Africa
  | "AS" // Asia
  | "EU" // Europe
  | "NA" // North America
  | "OC" // Oceania
  | "SA" // South America

/**
 * Azure CDN provided information about requester's geo location.
 */
export type AzureGeoCookieVal = {
  /**
   * Continent the HTTP request comes from
   */
  continent: AzureGeoContinent
  /**
   * Country the HTTP request comes from
   */
  country: string
}

/**
 * Try to get users geo location (continent and country) from a Cookie set by Azure CDN.
 *
 * Expects a cookie named "HArviaHttpGeo"  with a JSON string value.
 */
export function getHttpArea(): LocaleArea | undefined {
  const cookieVal = getCookie("HarviaHttpGeo")
  const httpGeo = cookieVal ? (JSON.parse(cookieVal) as AzureGeoCookieVal) : undefined
  if (!httpGeo) {
    return
  }
  return azureHttpGeoToArea(httpGeo)
}

/**
 * Country codes considered to be in "north-europe".
 * These are probably ISO 3166-1 alpha-2 country codes (uppercase).
 * Azure {@link https://learn.microsoft.com/en-us/azure/cdn/cdn-http-variables?toc=%2Fazure%2Ffrontdoor%2FTOC.json#definitions | documents}
 * them as "requester's country/region of origin through its country/region code."
 *
 */
const northEuropeCounries = ["FI", "SE", "EE", "DK", "NO"]

/**
 * Convert continent and country information to a {@link LocaleArea}
 * @param httpGeo - Azure CDN provided request geo information
 * @returns Harvia marketing area corresponding to given continent and country
 */
export function azureHttpGeoToArea(httpGeo?: AzureGeoCookieVal): LocaleArea | undefined {
  if (!httpGeo) {
    return
  }
  const { continent, country } = httpGeo

  switch (continent) {
    case "EU":
      if (northEuropeCounries.includes(country)) {
        return "north-europe"
      }
      return "europe"
    case "NA":
      // if (country === "US") {
      //   return "usa"
      // } else if (country === "CA") {
      //   return "canada"
      // }
      return "north-america"
    case "AF":
      return "africa"
    case "AS":
      return "asia"
    case "OC":
      return "oceania"
    case "SA":
      return "south-america"
    default:
      return undefined
  }
}
