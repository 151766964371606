import React, { ReactNode } from "react"
//import styles from "./ExternalLink.module.scss"

export type ExternalLinkProps = {
  children: ReactNode
} & React.AnchorHTMLAttributes<HTMLAnchorElement>

const ExternalLink = (props: ExternalLinkProps) => {
  //console.log('Rendering ExternalLink', props)
  const { children, ...anchorAttributes } = props
  return (
    <a target="_blank" rel="nofollow noopener" {...anchorAttributes}>
      {props.children}
    </a>
  )
}

export default ExternalLink
