import { graphql, useStaticQuery } from "gatsby"
import React from "react"
import { IPageContext, PageContext } from "../context/PageContext"

let _store: Record<string, string> | null = null

type RoutePathMapData = {
  allSitePage: {
    nodes: Array<{
      path: string
      pageContext: IPageContext
    }>
  }
}

/**
 * Palauttaa Dato CMS routeId:tä vastaavan, routesta tehdyn sivun URL polun
 */
const useArticlePath = () => {
  const { locale: contextLocale } = React.useContext(PageContext)

  const data = useStaticQuery<RoutePathMapData>(
    graphql`
      query DatoCmsArticlePathMap {
        allSitePage(
          filter: {
            componentChunkName: { eq: "component---gatsby-theme-harvia-src-gatsby-templates-dato-article-tsx" }
          }
        ) {
          nodes {
            path
            pageContext
          }
        }
      }
    `
  )

  // console.debug("useArticlePath()", data)

  if (_store === null) {
    const pathMap: Record<string, string> = Object.create(null)
    data.allSitePage.nodes.forEach(n => {
      pathMap[`${n.pageContext.originalId}-${n.pageContext.locale}`] = n.path
    })
    _store = pathMap
  }

  function getArticlePath(originalId?: string | null, locale?: string): string | undefined {
    const toLocale = locale || contextLocale
    // console.debug(
    //   `getArticlePath() originalId: "${originalId}", locale: "${toLocale}". In store? ${
    //     _store && `${originalId}-${toLocale}` in _store
    //   }`,
    //   _store
    // )
    return _store ? _store[`${originalId}-${toLocale}`] : undefined
  }

  return getArticlePath
}

export default useArticlePath
