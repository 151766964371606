import { DatoLocale, LocaleArea } from "gatsby-theme-harvia/src/types"

/**
 * Top level location areas
 */
// type AreaTitleKey = Exclude<LocaleArea, "usa" | "canada"> | "north-america"
type AreaTitleKey = LocaleArea

/**
 * Sub level location areas
 */
// type SubAreaTitleKey = Extract<LocaleArea, "usa" | "canada">

export type AreaKey = AreaTitleKey // | SubAreaTitleKey

type LocaleOptionMenuConfig = Map<AreaKey, AreaConfig[]>

export type AreaConfig = {
  locales: DatoLocale[]
  /** Unique ID used as `key` when looping items in React */
  id: string
  // subArea?: SubAreaTitleKey
  external?: { locale: string; href: string }
}

/**
 * Location dialog menu options.
 */
const localeOptions: LocaleOptionMenuConfig = new Map([
  ["north-europe", [{ id: "main", locales: ["fi", "en", "sv", "et"] }]],
  ["europe", [{ id: "main", locales: ["de", "en"] }]],
  [
    "north-america",
    [
      { id: "usa" /*, subArea: "usa"*/, locales: ["en-US"] },
      // { id: "canada", subArea: "canada", locales: ["en"] },
    ],
  ],
  ["south-america", [{ id: "main", locales: ["en"] }]],
  ["asia", [{ id: "main", external: { locale: "jp", href: "https://harvia.jp" }, locales: ["en"] }]],
  ["middle-east", [{ id: "main", locales: ["en"] }]],
  ["africa", [{ id: "main", locales: ["en"] }]],
  ["oceania", [{ id: "main", locales: ["en"] }]],
  ["global", [{ id: "main", locales: ["en"] }]],
])

export default localeOptions
