import React, { ButtonHTMLAttributes, ReactNode } from "react"
import * as styles from "./Button.module.scss"

export type ButtonPriorityProp = "primary" | "secondary"
export type ButtonSizeProp = "normal" | "flat" | "small"

export type ButtonProps = {
  /**
   * Button importance
   */
  priority?: ButtonPriorityProp

  /**
   * Button size
   */
  size?: ButtonSizeProp
  /**
   * Button contents
   */
  label: string | ReactNode
  /**
   * Optional click handler
   */
  onClick?: () => void
} & ButtonHTMLAttributes<HTMLButtonElement>

/**
 * Primary UI component for user interaction
 */
const Button: React.FC<ButtonProps> = ({ priority = "default", size = "normal", label, ...props }) => {
  // console.debug("Rendering Button", props)
  const classNames = [props.className, styles.button]
  if (styles[size]) {
    classNames.push(styles[size])
  }
  if (styles[priority]) {
    classNames.push(styles[priority])
  }
  // const { type = "button" } = props
  return (
    <button {...props} className={classNames.filter(Boolean).join(" ")}>
      {label}
    </button>
  )
}

export default Button
