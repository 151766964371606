import { isString } from "./utils"

/**
 * All possible "locale" values defined in Dato as Supported languages.
 * https://harvia.admin.datocms.com/admin/environment
 */
const datoLocales = ["en", "fi", "sv", "de", "fr", "es", "ru", "it", "et", "pl", "en-US"] as const

/**
 * Supported locale in Dato.
 */
export type DatoLocale = (typeof datoLocales)[number]

export const pimUnitSystems = ["metric", "imperial"] as const
export type PimUnitSystem = (typeof pimUnitSystems)[number]

export type Nullable<T> = { [K in keyof T]: T[K] | null }

const area = [
  "north-europe",
  "europe",
  "africa",
  "north-america",
  "south-america",
  "asia",
  "middle-east",
  "oceania",
  "global",
] as const

export type LocaleArea = (typeof area)[number]

export function isLocaleArea(val: unknown): val is LocaleArea {
  return isString(val) && area.includes(val as LocaleArea)
}
