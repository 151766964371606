import React, { createContext, useContext, useState } from "react"
import DatoLocaleDialog from "../components/dato/DatoLocaleDialog"

const LocaleDialogContext = createContext<LocaleDialogContextValue>([false, () => undefined])

type LocaleDialogProviderProps = {
  value: boolean
  children: React.ReactNode
}

type LocaleDialogContextValue = readonly [
  isLocaleDialogOpen: boolean,
  setLocaleDialogOpen: React.Dispatch<React.SetStateAction<boolean>>
]

/**
 * Get the language/area modal state (open or closed) and a function to change that
 */
export function useLocaleDialog(): LocaleDialogContextValue {
  const context = useContext(LocaleDialogContext)

  if (!context) {
    throw new Error("useLocaleDialog() must be used within a LocaleDialogContext")
  }
  return context
}

/**
 * Renders location and language dialog/modal (opened or closed depending on props) to DOM
 * and sets and initializes a state dispatch function.
 * Intented to be a wrapper for whole pages (see `gatsby-ssr.tsx`).
 *
 * Both open/close state and the function to switch the state are made available by `useLocaleDialog()´ hook.
 */
export const LocaleDialogContextProvider = (props: LocaleDialogProviderProps) => {
  // console.debug("Rendering LocaleDialogContextProvider", props)

  const [isOpen, setIsOpen] = useState(props.value)

  return (
    <LocaleDialogContext.Provider value={[isOpen, setIsOpen]}>
      {props.children}
      <DatoLocaleDialog />
    </LocaleDialogContext.Provider>
  )
}
